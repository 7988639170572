import React from "react"
import { AppSlider, GalleryItem } from '@common'
import Description from "./Description"
import Attributes from "./Attributes"
import AddToCartWithVariants from "./AddToCartWithVariants"
import { CrossSellingSlider} from './Sliders'

const gallerySettings = {
	slidesToShow: 1,
	responsive: [],
}

const VariableProduct = ({
  addToCart,
  product,
  onGalleryClick
}) => {
  return (
    <React.Fragment>
      <div className="product">
        <div className="container">
          <div className="row">
          <div className='col-sm-12 col-md-5 gallery-slider'>
          <AppSlider
            customSettings={gallerySettings}
            SliderItem={GalleryItem}
            items={[product.image, ...product.galleryImages.nodes]}
            onSlideClick={onGalleryClick}
            />
          </div>
            <div className="col-sm-12 col-md-7">
              <Description description={product.shortDescription} />
              {product.attributes && (
                <div className="row">
                  <div className="col">
                    <Attributes attributes={product.attributes.nodes} />
                  </div>
                </div>
              )}
              <AddToCartWithVariants
                variableProduct={product}
                onAddToCart={item => {
                  addToCart({ ...item, image: product.image })
                }}
              />
            </div>
          </div>
          <Description description={product.description} />
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default VariableProduct
