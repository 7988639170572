import React from 'react'
import {RadioButtons} from '@common'

const Variants = ({variations, onVariantChange, selected}) => {
  const sorted = variations.nodes.map((el)=>{
    el.name = el.name.split("-").pop()
    return el
  })
    return (
      <div className="product-variants">
      <div className="product-variants-item">
        <h5>Warianty:</h5>
      </div> 
      <RadioButtons 
        items={sorted}
        itemMapper={(el)=>({
          value: el.variationId,
          checked:el.variationId === selected,
          onChange:()=>{
            onVariantChange(el)
          },
          name: el.name
        })}
      />
 
      </div>
    );
  };
  

export default Variants