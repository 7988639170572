import React, { useRef, useState } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import {
	Layout,
	Head,
	Breadcrumbs,
	Mandala,
	Modal,
	ScalableImage, ResizedImage,
	AppSlider, ProductItem
} from '@common'
import { NewsletterSection } from '@sections'

import { SimpleProduct, VariableProduct } from '@pages/Product'

import { cartActions } from '../state'
import './styles/product.scss'

const selectProductType = (type)=>{
  switch(type) {
    case "SIMPLE":
      return SimpleProduct
    case 'VARIABLE':
      return VariableProduct
    default:
      return SimpleProduct
  }
}

const customSliderSettings = {
	slidesToShow: 4,
	responsive: [],
}

const Product = (props) => {
	const modal = useRef(null)
	const [galleryElement, setGalleryElement] = useState({})

	const { pageContext, addToCart, resetCart, onChange } = props
	const { page } = pageContext
	const { nodes } = page.productCategories


	const ProductType = selectProductType(page.type)

	return (
		<Layout
			seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}
			title={page.name}
		>
			<Modal title={'Podgląd zdjęcia'} ref={modal}>
				<div className='row justify-content-center'>
					{/* mobile */}
					<div className='d-md-none'>
						<ResizedImage srcSet={galleryElement.srcSet} size={'600w'} />
					</div>
					{/* desktop */}
					<div className='d-none d-md-block'>
						<ScalableImage srcSet={galleryElement.srcSet} />
					</div>
				</div>
			</Modal>
			<div className='product'>
				<div className='container'>
					<Breadcrumbs
						elements={[
							{ label: 'Kategorie', url: '/categories/' },
							{
								label: nodes[0].name,
								url: `/categories/${nodes[0].slug}`,
							},
							{
								label: page.name,
								url: `/products/${page.slug}/`,
							},
						]}
					/>
					<ProductType
						product={page}
						addToCart={addToCart}
						resetCart={resetCart}
						onGalleryClick={(element) => {
							setGalleryElement(element)
							modal.current.toggle()
						}}
					/>
					{page.crossSell.nodes.length > 0 &&
						<AppSlider 
								title={'Polecamy także'}
								items={page.crossSell.nodes}
									SliderItem={ProductItem}
									customSettings={customSliderSettings}
								/>
					}
				<div className='row justify-content-center'>
					<Link
					className='app-button alt'
					to={`/categories/${nodes[0].slug}`}
				>
					Wróc do {nodes[0].name}
				</Link>
			</div>
				</div>
			</div>

			<NewsletterSection />
		</Layout>
	)
}

const mapStateToProps = (state) => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Product)
