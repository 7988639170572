import React, { Component } from 'react'
import {QuantityChanger} from '@common'


const cleanPrice = (price) => {
	const _p = price.replace(' ','')
	return parseFloat(_p.split('&nbsp;')[0])
}

const Prices = ({ sale, regularValue, value })=>{
	return (<div className='product-price'>
	{sale && <span>{regularValue}PLN</span>}{' '}
	{value}PLN{' '}
</div>)
}

class AddToCart extends Component {
	state = {
		value: null,
		regularValue: null,
		quantity: 1,
		product_id: null,
		variation_id: null,
		variant: null,
		sale: false,
		price: '',
		regularPrice: '',
		unit: 'PLN',
		name: '',
		slug: '',
	}

	changeQty = (val) => {
		const _number = Number(val)
		if (_number <= 0 || Number.isNaN(_number)) {
			return false
		} else {
			this.setState({
				quantity: _number,
				value: _number * this.state.price,
				regularValue: _number * this.state.regularPrice,
			})
		}
	}

	onInit = () => {
		if (this.props.salePrice) {
			this.setState({
				product_id: this.props.productId,
				// variation_id:this.props.variationId,
				name: this.props.name,
				slug: this.props.slug,
				sale: true,
				price: cleanPrice(this.props.salePrice),
				regularPrice: cleanPrice(this.props.regularPrice),
				regularValue: cleanPrice(this.props.regularPrice),
				value: cleanPrice(this.props.salePrice),
				quantity: 1,
			})
		} else {
			this.setState({
				product_id: this.props.productId,
				// variation_id:this.props.variationId,
				name: this.props.name,
				slug: this.props.slug,
				price: cleanPrice(this.props.regularPrice),
				regularPrice: cleanPrice(this.props.regularPrice),
				regularValue: cleanPrice(this.props.regularPrice),
				value: cleanPrice(this.props.regularPrice),
				quantity: 1,
			})
		}
	}
	componentDidMount() {
		this.onInit()
	}
	render() {
		const { quantity, value, regularValue, sale } = this.state
		return (
			<div>
				<div className='row justify-content-center'>
					<QuantityChanger onChange={this.changeQty} quantity={quantity}/>
					<Prices {...{value, regularValue, sale}}/>
				</div>
				<div className='row'>
					<div className='col-12 col-md-12 col-xl-6'>
						<a
							className='product-btn'
							onClick={() => {
								this.props.onAddToCart(this.state)
								this.onInit()
							}}
						>
							Dodaj do koszyka
						</a>
					</div>
					<div className='col-12 col-md-12 col-xl-6'>
						<a className='product-btn alt' href='/checkout/'>
							Przejdź do płatności
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default AddToCart
