import React from 'react'
import { Stepper, Modal, AppSlider, GalleryItem } from '@common'
import { CrossSellingSlider} from './Sliders'
import Description from './Description'
import Attributes from './Attributes'
import AddToCart from './AddToCart'

const gallerySettings = {
	slidesToShow: 1,
	responsive: [],
}


const SimpleProduct = (props) => {
	const { addToCart, product, onGalleryClick } = props
	return (
		<React.Fragment>
			<div className='product'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12 col-md-5 gallery-slider'>
							<AppSlider
								customSettings={gallerySettings}
								SliderItem={GalleryItem}
								items={[product.image, ...product.galleryImages.nodes]}
								onSlideClick={onGalleryClick}
								/>
						</div>
						<div className='col-sm-12 col-md-7'>
							<Description
								description={product.shortDescription}
							/>
							{product.attributes && (
								<Attributes
									attributes={product.attributes.nodes}
								/>
							)}
							<AddToCart
								{...product}
								onAddToCart={(item) => {
									addToCart({
										...item,
										image: product.image,
									})
								}}
							/>
							<hr />
						</div>
					</div>
					<Description
						description={product.description}
					/>
				</div>
			</div>
		</React.Fragment>
	)
}

export default SimpleProduct
